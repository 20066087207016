<template>
  <!-- 收支明细 -->
  <div class="nav">
    <div v-for="(item, index) in detailArr" :key="index">
      <div class="titlenav d-flex f-13">
        <div>
          {{ item.createdAt.slice(0, 19) }}
        </div>
        <div>
          {{ item.remark }}
        </div>
        <div>{{ item.bean }}</div>
        <div>{{ item.finalBean }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getdetail } from "../../../api/index";
import { getUserInfo } from "../../../api/index";
export default {
  data() {
    return {
      detailArr: [],
      money: 0,
    };
  },
  mounted() {
    getdetail().then((res) => {
      console.log(res);

      this.detailArr = res.data.data;
      // console.log(this.detailArr);
    });
    getUserInfo().then((res) => {
      this.money = res.data.user.bean;
    });
  },
};
</script>

<style scoped lang="scss">
.nav {
  width: 100%;
  height: 100%;
  overflow: auto;
  .titlenav {
    justify-content: space-between;
    color: white;
    background-color: rgba(60, 60, 60, 0.39);
    padding: 15px 0;
    border-bottom: 1px solid #0c0d21;
    div {
      text-align: center;
    }
    p {
      font-size: 18px;
      color: gold;
      font-weight: bolder;
    }
  }
}
</style>
